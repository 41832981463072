import React, { useState, useEffect } from 'react'
import { Button, TextField, Grid } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import { Roles, Permissions } from '../../../../entities/permissions'
import {
  useUserState,
  hasPermission,
} from '../../../../context/user/UserContext'

// styles
import useStyles from './styles'
import BaseDialog from '../../../BaseDialog'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function ModifyUserDialog({
  title,
  open,
  onClose,
  onModified,
  onOpenEditPasswordDialog,
  enablePasswordChange,
  userToModify,
  modifyMe,
  isContractor,
  onOpenEditNotificationsDialog,
  enableNotificationsChange,
}) {
  const classes = useStyles()

  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { user } = useUserState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)
  const [constructeur, setConstructor] = useState(null)
  const [operators, setOperators] = useState([])
  const [member, setMember] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setFirstName(userToModify.firstName)
    setLastName(userToModify.lastName)
    setEmail(userToModify.email)
    setRole({
      id: userToModify.role,
      title: userToModify.roleName,
    })
    setConstructor(
      userToModify.constructor
        ? {
            id: userToModify.constructor.id,
            title: userToModify.constructor.name,
          }
        : null
    )
    setOperators(
      userToModify.operators
        ? userToModify.operators.map((op) => ({
            id: op.id,
            title: op.name,
          }))
        : []
    )
    setMember(
      userToModify.member
        ? {
            id: userToModify.member.id,
            title: userToModify.member.name,
          }
        : null
    )
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (firstName.length === 0) {
      setError('firstName_required')
      return
    }

    if (lastName.length === 0) {
      setError('lastName_required')
      return
    }

    if (!isContractor) {
      if (!role) {
        setError('role_required')
        return
      }
      if (role.id === Roles.CONSTRUCTOR && !constructeur) {
        setError('constructor_required')
        return
      }
      if (role.id === Roles.OPERATOR) {
        if (!operators || !Array.isArray(operators)) {
          setError('operator_required')
          return
        }
        if (operators.length === 0) {
          setError('operator_required')
          return
        }
      }
      if (role.id === Roles.MEMBER && !member) {
        setError('member_required')
        return
      }
    }

    setLoading(true)
    const userId = modifyMe ? 'me' : userToModify.id
    if (isContractor) {
      dataProvider.contractor
        .put(userId, firstName, lastName)
        .then(() => {
          dataProvider.cache.reset()
          showNotification('success', t('modify-user-dialog.success'))
          onModified()
        })
        .catch(() => {
          showNotification('error', t('errors.api-error'))
        })
        .then(() => resetState())
    } else {
      const data = {
        firstName,
        lastName,
        roleId: role.id,
        constructorId:
          role.id === Roles.CONSTRUCTOR ? constructeur.id : undefined,
        operators:
          role.id === Roles.OPERATOR ? operators.map((op) => op.id) : undefined,
        memberId: role.id === Roles.MEMBER ? member.id : undefined,
      }
      dataProvider
        .update('users', { data, id: userId })
        .then(() => {
          dataProvider.cache.reset()
          showNotification('success', t('modify-user-dialog.success'))
          onModified()
        })
        .catch(() => {
          showNotification('error', t('errors.api-error'))
        })
        .then(() => resetState())
    }
  }

  useEffect(() => {
    if (userToModify) {
      setFirstName(userToModify.firstName)
      setLastName(userToModify.lastName)
      setEmail(userToModify.email)
      setRole({
        id: userToModify.role,
        title: userToModify.roleName,
      })
      setConstructor(
        userToModify.constructor
          ? {
              id: userToModify.constructor.id,
              title: userToModify.constructor.name,
            }
          : null
      )
      setOperators(
        userToModify.operators
          ? userToModify.operators.map((op) => ({
              id: op.id,
              title: op.name,
            }))
          : []
      )
      setMember(
        userToModify.member
          ? {
              id: userToModify.member.id,
              title: userToModify.member.name,
            }
          : null
      )
    }
  }, [userToModify])

  function renderConstructorFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setConstructor}
          value={constructeur}
          label={t('create-user-dialog.constructor-label')}
          resource="/constructors/autocomplete"
          error={error === 'constructor_required'}
          required
          disabled={
            !hasPermission(Permissions.PAGE_USERS_MODIFY_USER_TYPE, user)
          }
          canDelete
        />
      </Grid>
    )
  }

  function renderOperatorsFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setOperators}
          value={operators}
          label={t('create-user-dialog.operator')}
          resource="/operators/autocomplete"
          error={error === 'operator_required'}
          required
          multiple
          canDelete
        />
      </Grid>
    )
  }

  function renderMemberFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setMember}
          value={member}
          label={t('create-user-dialog.member')}
          resource="/members/autocomplete"
          error={error === 'member_required'}
          required
          disabled={
            !hasPermission(Permissions.PAGE_USERS_MODIFY_USER_TYPE, user)
          }
          canDelete
        />
      </Grid>
    )
  }

  function renderSelectedRoleFields() {
    if (!role) {
      return <></>
    }

    if (role.id === Roles.CONSTRUCTOR) {
      return renderConstructorFields()
    }
    if (role.id === Roles.OPERATOR) {
      return renderOperatorsFields()
    }
    if (role.id === Roles.MEMBER) {
      return renderMemberFields()
    }

    return <></>
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
      openButtonTitle={t('common.modify')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            id="firstName"
            label={t('common.firstName')}
            error={error === 'firstName_required'}
            helperText={t('common.required')}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="lastName"
            label={t('common.lastName')}
            error={error === 'lastName_required'}
            helperText={t('common.required')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="email"
            label={t('common.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            fullWidth
            disabled
          />
        </Grid>
        {enablePasswordChange && (
          <Grid item>
            <Button
              onClick={onOpenEditPasswordDialog}
              color="primary"
              className={classes.changePasswordButton}
            >
              {t('common.change-password')}
            </Button>
          </Grid>
        )}
        {enableNotificationsChange && (
          <Grid item>
            <Button
              onClick={onOpenEditNotificationsDialog}
              color="primary"
              className={classes.changePasswordButton}
            >
              {t('common.manage-notifications')}
            </Button>
          </Grid>
        )}
        {!isContractor && (
          <Grid item>
            <AutoCompleteInput
              onChange={setRole}
              value={role}
              label={t('create-user-dialog.role')}
              resource="/users/roles-autocomplete"
              error={error === 'role_required'}
              required
              disabled={
                !hasPermission(Permissions.PAGE_USERS_MODIFY_USER_TYPE, user)
              }
              canDelete
            />
          </Grid>
        )}
        {renderSelectedRoleFields()}
      </Grid>
    </BaseDialog>
  )
}
