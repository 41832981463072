import { Roles } from './permissions'
const DAILY_ADMISSIONS = 0

const notificationTypes = [DAILY_ADMISSIONS]

// Map roles to notification types
const notificationTypePerRole = {
  ['super-admin']: [DAILY_ADMISSIONS],
  ['admin']: [DAILY_ADMISSIONS],
  [Roles.CATP]: [],
  [Roles.MEMBER]: [],
  [Roles.OPERATOR]: [],
  [Roles.CONTRACTOR]: [],
  [Roles.CONSTRUCTOR]: [],
}

const translations = {
  [DAILY_ADMISSIONS]: 'notification-type.daily-admissions',
}

function translate(notificationType, t) {
  return t(translations[notificationType])
}

function hasNotifications(role) {
  return notificationTypePerRole[role].length > 0
}

function getNotificationTypes(role) {
  return notificationTypePerRole[role]
}

export { notificationTypes, translate, hasNotifications, getNotificationTypes }
