import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid, Button, useTheme, Typography } from '@mui/material'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import { useQueryState } from '../../utils/query-state'

// components
import PageTitle from '../../components/PageTitle'
import { useDataApi } from '../../context/DataApiContext'
// import Loading from '../../components/Loading'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import LineChart from 'shared-components/charts/line-chart'
import BarsChart from 'shared-components/charts/bars-chart'
import PieChart from 'shared-components/charts/pie-chart'
import DatePicker from '../../components/DatePicker'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import { toLocalISOString, toLocalDateString } from '../../utils/formatters'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function Statistics({ history }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [isRefreshing, setIsRefreshing] = useState(false)
  const [failedLoad, setFailedLoad] = useState(false)
  const [filterBadges, setFilterBadges] = useState([])
  const [controlCounts, setControlCounts] = useState(null)
  const [controlByControllerCounts, setControlByControllerCounts] =
    useState(null)
  const [controlByVehicleTypeCounts, setControlByVehicleTypeCounts] =
    useState(null)
  const [vehicleConformitiesCounts, setVehicleConformitiesCounts] =
    useState(null)
  const [
    nonConformitiesNumberByVehicleType,
    setNonConformitiesNumberByVehicleType,
  ] = useState(null)
  const [
    nonConformitiesNumberByControlNumber,
    setNonConformitiesNumberByControlNumber,
  ] = useState(null)
  const [statisticsControlCountsLoading, setStatisticsControlCountsLoading] =
    useState(true)
  const [
    statisticsControlByControllerCountsLoading,
    setStatisticsControlByControllerCountsLoading,
  ] = useState(true)
  const [
    statisticsControlByVehicleTypeCountsLoading,
    setStatisticsControlByVehicleTypeCountsLoading,
  ] = useState(true)
  const [
    statisticsVehicleConformitiesCountsLoading,
    setStatisticsVehicleConformitiesCountsLoading,
  ] = useState(true)
  const [
    statisticsNonConformitiesNumberByVehicleTypeLoading,
    setStatisticsNonConformitiesNumberByVehicleTypeLoading,
  ] = useState(true)
  const [
    statisticsNonConformitiesNumberByControlNumberLoading,
    setStatisticsNonConformitiesNumberByControlNumberLoading,
  ] = useState(true)

  // START: Statistics Filters

  const query = useQuery()

  // Set start date to 1 year ago
  const [startDate, setStartDate] = useQueryState({
    paramName: 'startDate',
    history,
    query,
    fromQuery: useCallback(
      (value) =>
        value
          ? new Date(value)
          : new Date(new Date().setMonth(new Date().getMonth() - 12)),
      []
    ),
    toQuery: useCallback((value) => toLocalISOString(value), []),
  })
  const [endDate, setEndDate] = useQueryState({
    paramName: 'endDate',
    history,
    query,
    fromQuery: useCallback(
      (value) => (value ? new Date(value) : new Date()),
      []
    ),
    toQuery: useCallback((value) => toLocalISOString(value), []),
  })
  const [vehicleTypes, setVehicleTypes] = useQueryState({
    paramName: 'vehicleTypes',
    history,
    query,
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })
  const [submittedByUsers, setSubmittedByUsers] = useQueryState({
    history,
    query,
    paramName: 'submittedByUsers',
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })

  const [depots, setDepots] = useQueryState({
    history,
    query,
    paramName: 'depots',
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })

  const [operators, setOperators] = useQueryState({
    history,
    query,
    paramName: 'operators',
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })

  const [members, setMembers] = useQueryState({
    history,
    query,
    paramName: 'members',
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })

  const [constructors, setConstructors] = useQueryState({
    history,
    query,
    paramName: 'constructors',
    fromQuery: useCallback((value) => {
      // Convert a comma-separated string into an array of objects with only IDs
      if (value) {
        return value.split(',').map((id) => ({ id: parseInt(id, 10) }))
      }
      return [] // Default to an empty array
    }, []),
    toQuery: useCallback((value) => {
      // Convert an array of objects or integers into a comma-separated string
      if (value.length > 0) {
        if (typeof value[0] === 'object') {
          return value.map((item) => item.id).join(',')
        }
        return value.join(',')
      }
      return ''
    }, []),
    defaultValue: [], // Default to an empty array when no query parameter exists
  })

  const processFilterBadges = () => {
    const filterBadges = []
    if (startDate) {
      filterBadges.push({
        name: t('common.startDate'),
        value: toLocalDateString(startDate),
      })
    }
    if (endDate) {
      filterBadges.push({
        name: t('common.endDate'),
        value: toLocalDateString(endDate),
      })
    }
    if (vehicleTypes.length > 0) {
      filterBadges.push({
        name:
          vehicleTypes.length > 1
            ? t('common.vehicleTypes')
            : t('common.vehicleType'),
        value: vehicleTypes.map((vehicleType) => vehicleType.title).join(', '),
      })
    }
    if (submittedByUsers.length > 0) {
      filterBadges.push({
        name:
          submittedByUsers.length > 1
            ? t('common.submitted-by-users')
            : t('common.submitted-by-user'),
        value: submittedByUsers
          .map((submittedByUser) => submittedByUser.title)
          .join(', '),
      })
    }
    if (depots.length > 0) {
      filterBadges.push({
        name: depots.length > 1 ? t('common.depots') : t('common.depot'),
        value: depots.map((depot) => depot.title).join(', '),
      })
    }
    if (operators.length > 0) {
      filterBadges.push({
        name:
          operators.length > 1 ? t('common.operators') : t('common.operator'),
        value: operators.map((operator) => operator.title).join(', '),
      })
    }
    if (members.length > 0) {
      filterBadges.push({
        name: members.length > 1 ? t('common.members') : t('common.member'),
        value: members.map((member) => member.title).join(', '),
      })
    }
    if (constructors.length > 0) {
      filterBadges.push({
        name:
          constructors.length > 1
            ? t('common.constructeurs')
            : t('common.constructeur'),
        value: constructors.map((constructor) => constructor.title).join(', '),
      })
    }

    setFilterBadges(filterBadges)
  }

  const computedFilters = useMemo(() => {
    console.log('submittedByUsers', submittedByUsers)
    console.log('vehicleTypes', vehicleTypes)
    const filtersObj = {
      startDate: startDate instanceof Date ? toLocalISOString(startDate) : null,
      endDate: endDate instanceof Date ? toLocalISOString(endDate) : null,
      vehicleTypeIds: vehicleTypes
        ? vehicleTypes.map((vehicleType) => vehicleType.id)
        : [],
      submittedByUserIds: submittedByUsers
        ? submittedByUsers.map((submittedByUser) => submittedByUser.id)
        : [],
      depotIds: depots ? depots.map((depot) => depot.id) : [],
      operatorIds: operators ? operators.map((operator) => operator.id) : [],
      memberIds: members ? members.map((member) => member.id) : [],
      constructorIds: constructors
        ? constructors.map((constructor) => constructor.id)
        : [],
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )

    return filtersObj
  }, [
    startDate,
    endDate,
    vehicleTypes,
    submittedByUsers,
    depots,
    operators,
    members,
    constructors,
  ])

  // Update filters badges when refreshing the page and after the values where fetched from the API via AutoCompleteInput
  useEffect(() => {
    if (
      isRefreshing &&
      !vehicleTypes.some((vehicleType) => !vehicleType.title) &&
      !submittedByUsers.some((submittedByUser) => !submittedByUser.title) &&
      !depots.some((depot) => !depot.title) &&
      !operators.some((operator) => !operator.title) &&
      !members.some((member) => !member.title) &&
      !constructors.some((constructor) => !constructor.title)
    ) {
      processFilterBadges()
      setIsRefreshing(false)
    }

    if (
      vehicleTypes.some((vehicleType) => !vehicleType.title) ||
      submittedByUsers.some((submittedByUser) => !submittedByUser.title) ||
      depots.some((depot) => !depot.title) ||
      operators.some((operator) => !operator.title) ||
      members.some((member) => !member.title) ||
      constructors.some((constructor) => !constructor.title)
    ) {
      setIsRefreshing(true)
    }
  }, [vehicleTypes, submittedByUsers, depots, operators, members, constructors])

  const updateFilters = () => {
    getStatisticsData()
    processFilterBadges()
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setVehicleTypes([])
    setSubmittedByUsers([])
    setDepots([])
    setOperators([])
    setMembers([])
    setConstructors([])
    processFilterBadges()
  }

  // END: Statistics Filters

  function getStatisticsData() {
    setStatisticsControlCountsLoading(true)
    dataProvider
      .getList('/dashboard/statistics/controls-number-by-date-interval', {
        filters: computedFilters,
      })
      .then((response) => {
        setControlCounts(response.data)
        setStatisticsControlCountsLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsControlCountsLoading(false)
      })

    setStatisticsControlByControllerCountsLoading(true)
    dataProvider
      .getList('/dashboard/statistics/controls-number-by-controller', {
        filters: computedFilters,
      })
      .then((response) => {
        setControlByControllerCounts(response.data)
        setStatisticsControlByControllerCountsLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsControlByControllerCountsLoading(false)
      })

    setStatisticsVehicleConformitiesCountsLoading(true)
    dataProvider
      .getList('/dashboard/statistics/vehicles-conformities-number', {
        filters: computedFilters,
      })
      .then((response) => {
        setVehicleConformitiesCounts(response.data)
        setStatisticsVehicleConformitiesCountsLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsVehicleConformitiesCountsLoading(false)
      })

    setStatisticsControlByVehicleTypeCountsLoading(true)
    dataProvider
      .getList('/dashboard/statistics/controls-number-by-vehicle-type', {
        filters: computedFilters,
      })
      .then((response) => {
        setControlByVehicleTypeCounts(response.data)
        setStatisticsControlByVehicleTypeCountsLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsControlByVehicleTypeCountsLoading(false)
      })

    setStatisticsNonConformitiesNumberByVehicleTypeLoading(true)
    dataProvider
      .getList(
        '/dashboard/statistics/non-conformities-number-by-vehicle-type',
        {
          filters: computedFilters,
        }
      )
      .then((response) => {
        setNonConformitiesNumberByVehicleType(response.data)
        setStatisticsNonConformitiesNumberByVehicleTypeLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsNonConformitiesNumberByVehicleTypeLoading(false)
      })
    setStatisticsNonConformitiesNumberByControlNumberLoading(true)
    dataProvider
      .getList(
        '/dashboard/statistics/non-conformities-number-by-control-number',
        {
          filters: computedFilters,
        }
      )
      .then((response) => {
        setNonConformitiesNumberByControlNumber(response.data)
        setStatisticsNonConformitiesNumberByControlNumberLoading(false)
      })
      .catch(() => {
        setFailedLoad(true)
        setStatisticsNonConformitiesNumberByControlNumberLoading(false)
      })
  }

  useEffect(() => {
    getStatisticsData()
    processFilterBadges()
  }, [dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('statistics.title')} />
        </Grid>
      </Grid>
      <Grid container item className={classes.statisticsFiltersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid container item xs={12} md={6} alignItems="flex-end">
            <Grid item xs={12} md={5}>
              <DatePicker
                id="startDate"
                value={startDate}
                onChange={setStartDate}
                label={t('common.startDate')}
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" align="center">
                Au
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePicker
                id="endDate"
                value={endDate}
                onChange={setEndDate}
                label={t('common.endDate')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setVehicleTypes}
              label={t('common.vehicleType')}
              resource="vehicle-type/autocomplete"
              value={vehicleTypes}
              query={{ includeDisabled: false }}
              canDelete
              multiple
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setSubmittedByUsers}
              label={t('common.submitted-by-user')}
              resource="chassis/user-autocomplete"
              value={submittedByUsers}
              canDelete
              multiple
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setDepots}
              label={t('common.depot')}
              resource="depots/autocomplete"
              value={depots}
              canDelete
              multiple
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setOperators}
              label={t('common.operateur')}
              resource="operators/autocomplete"
              value={operators}
              canDelete
              multiple
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setMembers}
              label={t('common.member')}
              resource="members/autocomplete"
              value={members}
              canDelete
              multiple
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setConstructors}
              label={t('common.constructeur')}
              resource="constructors/autocomplete"
              value={constructors}
              canDelete
              multiple
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            justifyContent="flex-end"
            className={classes.statisticsActionsContainer}
          >
            <Grid item xs={12} md={2} xl={1}>
              <Button
                color="secondary"
                variant="contained"
                onClick={clearFilters}
                fullWidth
              >
                {t('common.clean')}
              </Button>
            </Grid>
            <Grid item xs={12} md={2} xl={1}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={updateFilters}
              >
                {t('common.search')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        className={classes.statisticsContainer}
      >
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <LineChart
            title={t('statistics.controls-timeline-chart.title')}
            noDataMessage={t('statistics.no-data-available')}
            data={controlCounts}
            isLoading={statisticsControlCountsLoading}
            xLabel={t('statistics.controls-timeline-chart.x-axis-label')}
            yLabel={t('statistics.controls-timeline-chart.y-axis-label')}
            tooltipLabel={t('statistics.controls-timeline-chart.tooltip-label')}
            style={{
              line: { data: { stroke: theme.palette.primary.main } },
              tooltip: { fill: theme.palette.primary.main, fontSize: 10 },
              xLabel: {
                axisLabel: { padding: 30, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              yLabel: {
                axisLabel: { padding: 40, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            activeFilters={filterBadges}
          ></LineChart>
        </Grid>
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <BarsChart
            title={t('statistics.controls-per-controller-chart.title')}
            noDataMessage={t('statistics.no-data-available')}
            data={controlByControllerCounts}
            isLoading={statisticsControlByControllerCountsLoading}
            xLabel={t('statistics.controls-per-controller-chart.x-axis-label')}
            yLabel={t('statistics.controls-per-controller-chart.y-axis-label')}
            tooltipLabel={t(
              'statistics.controls-per-controller-chart.tooltip-label'
            )}
            style={{
              chart: {
                padding: { top: 50, bottom: 80, left: 50, right: 20 },
              },
              bars: {
                data: {
                  stroke: 'none',
                  fill: theme.palette.primary.main,
                  fillOpacity: 1, // Ensure full color opacity
                  opacity: 1, // Disable any default opacity
                  boxShadow: 'none', // Ensure no shadow is applied
                },
              },
              tooltip: { fill: theme.palette.primary.main, fontSize: 10 },
              xLabel: {
                axisLabel: { padding: 70, fontSize: 11 },
                tickLabels: { fontSize: 8, angle: -45, textAnchor: 'end' },
              },
              yLabel: {
                axisLabel: { padding: 40, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            showAllTicks
            activeFilters={filterBadges.filter(
              (badge) =>
                (badge.name !== t('common.submitted-by-user') &&
                  badge.name !== t('common.submitted-by-users')) ||
                (badge.name === t('common.submitted-by-users') &&
                  submittedByUsers.length > 1) ||
                (badge.name === t('common.submitted-by-user') &&
                  submittedByUsers.length > 1)
            )}
            scrollLimit={15}
          ></BarsChart>
        </Grid>
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <BarsChart
            title={t('statistics.controls-per-vehicle-type-chart.title')}
            noDataMessage={t('statistics.no-data-available')}
            data={controlByVehicleTypeCounts}
            isLoading={statisticsControlByVehicleTypeCountsLoading}
            xLabel={t(
              'statistics.controls-per-vehicle-type-chart.x-axis-label'
            )}
            yLabel={t(
              'statistics.controls-per-vehicle-type-chart.y-axis-label'
            )}
            tooltipLabel={t(
              'statistics.controls-per-vehicle-type-chart.tooltip-label'
            )}
            style={{
              chart: {
                padding: { top: 50, bottom: 90, left: 50, right: 20 },
              },
              bars: {
                data: {
                  stroke: 'none',
                  fill: theme.palette.primary.main,
                  fillOpacity: 1, // Ensure full color opacity
                  opacity: 1, // Disable any default opacity
                  boxShadow: 'none', // Ensure no shadow is applied
                },
              },
              tooltip: {
                fill: theme.palette.primary.main,
                fontSize: 10,
              },
              xLabel: {
                axisLabel: { padding: 75, fontSize: 11 },
                tickLabels: { fontSize: 8, angle: -45, textAnchor: 'end' },
              },
              yLabel: {
                axisLabel: { padding: 40, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            showAllTicks
            activeFilters={filterBadges.filter(
              (badge) =>
                (badge.name !== t('common.vehicleType') &&
                  badge.name !== t('common.vehicleTypes')) ||
                (badge.name === t('common.vehicleTypes') &&
                  vehicleTypes.length > 1) ||
                (badge.name === t('common.vehicleType') &&
                  vehicleTypes.length > 1)
            )}
            scrollLimit={15}
            chartHeight={400}
            chartMinimumWidth={800}
          ></BarsChart>
        </Grid>
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <BarsChart
            title={t(
              'statistics.non-conformities-per-vehicle-type-chart.title'
            )}
            noDataMessage={t('statistics.no-data-available')}
            data={nonConformitiesNumberByVehicleType}
            isLoading={statisticsNonConformitiesNumberByVehicleTypeLoading}
            xLabel={t(
              'statistics.non-conformities-per-vehicle-type-chart.x-axis-label'
            )}
            yLabel={t(
              'statistics.non-conformities-per-vehicle-type-chart.y-axis-label'
            )}
            tooltipLabel={t(
              'statistics.non-conformities-per-vehicle-type-chart.tooltip-label'
            )}
            style={{
              chart: {
                padding: { top: 50, bottom: 90, left: 50, right: 20 },
              },
              bars: {
                data: {
                  stroke: 'none',
                  fill: theme.palette.primary.main,
                  fillOpacity: 1, // Ensure full color opacity
                  opacity: 1, // Disable any default opacity
                  boxShadow: 'none', // Ensure no shadow is applied
                },
              },
              tooltip: {
                fill: theme.palette.primary.main,
                fontSize: 10,
              },
              xLabel: {
                axisLabel: { padding: 75, fontSize: 11 },
                tickLabels: { fontSize: 8, angle: -45, textAnchor: 'end' },
              },
              yLabel: {
                axisLabel: { padding: 40, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            showAllTicks
            // Remove certain filters from the active filters
            activeFilters={filterBadges.filter(
              (badge) =>
                (badge.name !== t('common.vehicleType') &&
                  badge.name !== t('common.vehicleTypes')) ||
                (badge.name === t('common.vehicleTypes') &&
                  vehicleTypes.length > 1) ||
                (badge.name === t('common.vehicleType') &&
                  vehicleTypes.length > 1)
            )}
            scrollLimit={15}
            chartHeight={400}
            chartMinimumWidth={800}
          ></BarsChart>
        </Grid>
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <PieChart
            title={t('statistics.vehicles-conformity-report-pie-chart.title')}
            noDataMessage={t('statistics.no-data-available')}
            data={vehicleConformitiesCounts}
            isLoading={statisticsVehicleConformitiesCountsLoading}
            tooltipLabel={t(
              'statistics.vehicles-conformity-report-pie-chart.tooltip-label'
            )}
            enableTooltip
            style={{
              pie: {
                stroke: theme.palette.background.paper, // Optional: Adds a border for better separation
                strokeWidth: 1,
                labels: {
                  fill: theme.palette.text.primary,
                  fontSize: 8,
                  fontWeight: 'bold',
                },
                parent: { backgroundColor: 'transparent' }, // Set the background to transparent
              },
              tooltip: {
                fill: theme.palette.primary.main,
                fontSize: 10,
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            colorScale={[
              theme.palette.error.light,
              theme.palette.success.main,
              theme.palette.primary.main,
              theme.palette.warning.main,
            ]}
            // Remove certain filters from the active filters
            activeFilters={filterBadges.filter(
              (badge) =>
                badge.name !== t('common.submitted-by-user') &&
                badge.name !== t('common.submitted-by-users')
            )}
          ></PieChart>
        </Grid>
        {/* TODO: Add chart to group mutiple bars by vehicle type */}
        <Grid item lg={5} md={6} sm={8} xs={14}>
          <BarsChart
            title={t(
              'statistics.non-conformities-per-control-number-chart.title'
            )}
            noDataMessage={t('statistics.no-data-available')}
            data={nonConformitiesNumberByControlNumber}
            isLoading={statisticsNonConformitiesNumberByControlNumberLoading}
            xLabel={t(
              'statistics.non-conformities-per-control-number-chart.x-axis-label'
            )}
            yLabel={t(
              'statistics.non-conformities-per-control-number-chart.y-axis-label'
            )}
            tooltipLabel={t(
              'statistics.non-conformities-per-control-number-chart.tooltip-label'
            )}
            style={{
              chart: {
                padding: { top: 50, bottom: 90, left: 50, right: 20 },
              },
              bars: {
                data: {
                  stroke: 'none',
                  fill: theme.palette.primary.main,
                  fillOpacity: 1, // Ensure full color opacity
                  opacity: 1, // Disable any default opacity
                  boxShadow: 'none', // Ensure no shadow is applied
                },
              },
              tooltip: {
                fill: theme.palette.primary.main,
                fontSize: 10,
              },
              xLabel: {
                axisLabel: { padding: 75, fontSize: 11 },
                tickLabels: { fontSize: 8, angle: -45, textAnchor: 'end' },
              },
              yLabel: {
                axisLabel: { padding: 40, fontSize: 11 },
                tickLabels: { fontSize: 10 },
              },
              title: { variant: 'body1', fontWeight: 'bold' },
              activeFilters: {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            }}
            showAllTicks
            // Remove certain filters from the active filters
            activeFilters={filterBadges}
            scrollLimit={15}
            chartHeight={400}
            chartMinimumWidth={800}
          ></BarsChart>
        </Grid>
      </Grid>
    </>
  )
}
